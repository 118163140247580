import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseCongig = {
  apiKey: 'AIzaSyBFXWOUXS-VRQUW7ZWcKrHysCMTxw8OGB0',
  authDomain: 'devault-78fce.firebaseapp.com',
  projectId: 'devault-78fce',
  storageBucket: 'devault-78fce.appspot.com',
  messagingSenderId: '384040943267',
  appId: '1:384040943267:web:bed6196449f6cb3cecc916',
  measurementId: 'G-3XG8VC933S',
};

initializeApp(firebaseCongig);
export const auth = getAuth();

export const FIREBASE_MESSAGES_DB = 'messages';
