import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import emailjs from '@emailjs/browser';

import { store } from './redux/store';
import App from './App';
import './index.css';
import { AuthProvider } from './auth/AuthContext';

const container = document.getElementById('root')!;
const root = createRoot(container);

if (process.env.REACT_APP_ENABLE_MSW === 'true') {
  (async () => {
    const { initMsw } = await import('./mocks/browser');

    try {
      await initMsw();
    } catch (error) {
      console.error(error);
    }
  })();
}

emailjs.init({
  publicKey: 'YOUR_PUBLIC_KEY',
  // Do not allow headless browsers
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    list: ['foo@emailjs.com', 'bar@emailjs.com'],
    // The variable contains the email address
    watchVariable: 'userEmail',
  },
  limitRate: {
    // Set the limit rate for the application
    id: 'app',
    // Allow 1 request per 10s
    throttle: 10000,
  },
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
);
