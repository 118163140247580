import { Box, Button, Tab, Tabs, Typography } from '@mui/material';

import React, { useEffect } from 'react';

import { AccountBalanceWallet, AddRounded, ContentCopy, QrCode } from '@mui/icons-material';

import { useParams, useSearchParams } from 'react-router-dom';

import { type TWallet, useGetWalletsQuery } from '../../redux/api';
import { DrawerHeader } from '../Layout/Layout';
import useWalletCreateFlowContext from '../../context/WalletCreateFlowContext';
import WalletTab from './components/WalletTab';
import usePageTitle from '../../hooks/hooks';

export type TTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  wallet: TWallet;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Wallets: React.FC = () => {
  usePageTitle('deVaulty::Wallets');

  const { isFetching, data } = useGetWalletsQuery(null);
  const [value, setValue] = React.useState(0);
  const [searchParams] = useSearchParams();
  const { walletId = '' } = useParams();
  const { setOpen } = useWalletCreateFlowContext();

  const isFirstLogin = !!searchParams.get('firstLogin');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (walletId && data) {
      let walletIndex = 0;

      data.map((wallet, index) => {
        if (wallet.wallet_id === walletId) {
          walletIndex = index;
        }
      });

      if (walletIndex) {
        setValue(walletIndex);
      }
    }
  }, [data, walletId]);

  useEffect(() => {
    if (isFirstLogin) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DrawerHeader>
        <Typography noWrap component="div" flexGrow={1} variant="h6">
          Wallets
        </Typography>
        <Button
          startIcon={<AddRounded fontSize="inherit" />}
          sx={{ textTransform: 'none' }}
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          New wallet
        </Button>
      </DrawerHeader>
      <Tabs
        aria-label="basic tabs example"
        scrollButtons="auto"
        sx={{
          '.Mui-selected>div': {
            border: '1px solid #1976d2',
            borderRadius: 2,
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
        }}
        value={value}
        variant="scrollable"
        onChange={handleChange}
      >
        {data?.map((wallet, index) => (
          <Tab
            key={wallet.wallet_id}
            label={
              <Box
                sx={{
                  color: 'black',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  alignItems: 'center',
                  p: 1.5,
                  background: '#fff',
                  maxWidth: 230,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    overflow: 'clip',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    gap: 1,
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: '12px',
                      fontWeight: 500,
                      textAlign: 'left',
                    }}
                  >
                    {wallet.name}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                    <Typography
                      noWrap
                      sx={{ textTransform: 'none', fontSize: '11px', fontWeight: 500 }}
                      title={wallet.public_key}
                    >
                      {wallet.public_key.slice(0, 6) + '...' + wallet.public_key.slice(-4)}
                    </Typography>
                    <QrCode sx={{ width: 16, height: 16 }} />
                    <ContentCopy sx={{ width: 16, height: 16 }} />
                  </Box>
                </Box>
                <AccountBalanceWallet sx={{ width: 24, height: 24, color: 'primary.main' }} />
              </Box>
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {data?.map((wallet, index) => <WalletTab key={wallet.name} index={index} value={value} wallet={wallet} />)}
    </Box>
  );
};

export default Wallets;
