import { useEffect } from 'react';

import type React from 'react';

import './Website.css';

const Website: React.FC = () => {
  function smoothScrollTo(id: string) {
    const targetElement = document.querySelector(id) as HTMLElement | undefined;

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const handleAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const menu = document.querySelector('#menu');

    if (menu) {
      menu.classList.remove('open');
    }

    smoothScrollTo(event.currentTarget.hash);
  };

  const handleTabClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const isOpened = event.currentTarget.classList.contains('active');

    if (!isOpened) {
      event.currentTarget.classList.add('active');
    } else {
      event.currentTarget.classList.remove('active');
    }
  };

  const outsideMenuClick = (event: MouseEvent) => {
    const menu = document.getElementById('menu') as HTMLElement;
    const menuToggle = document.getElementById('mobile-menu-toggle') as HTMLElement;

    if (!(event.target instanceof Node)) {
      return;
    }

    if (!menu.contains(event.target) && !menuToggle.contains(event.target)) {
      menu.classList.remove('open');
    } else {
      // prevent closing menu when clicking inside it
      event.stopPropagation();
    }
  };

  useEffect(() => () => {
    document.removeEventListener('click', outsideMenuClick);
  });

  const handleMenuToggle = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const menu = document.querySelector('#menu') as HTMLElement;

    if (menu.classList.contains('open')) {
      menu.classList.remove('open');
      document.removeEventListener('click', outsideMenuClick);
    } else {
      menu.classList.add('open');
      document.addEventListener('click', outsideMenuClick);
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <div className="website-container" id="home">
      <header className="header" id="home">
        <div className="container">
          <div className="logo">
            <a href="#home" onClick={handleAnchorClick}>
              <img alt="Logo" src="images/logo_full.svg" />
            </a>
          </div>
          <a className="mobile-menu-toggle" href="#menu" id="mobile-menu-toggle" onClick={handleMenuToggle}>
            <img alt="Menu Toggle" height={24} src="images/website/menu-icon.svg" width={24} />
          </a>
          <nav className="nav" id="menu">
            <a href="#home" onClick={handleAnchorClick}>
              Home
            </a>
            <a href="#features" onClick={handleAnchorClick}>
              Features
            </a>
            <a href="#product" onClick={handleAnchorClick}>
              Product
            </a>
            <a href="#team" onClick={handleAnchorClick}>
              Team
            </a>
            <a href="#contact" onClick={handleAnchorClick}>
              Contact
            </a>
            <a className="button contained" href="#contact" onClick={handleAnchorClick}>
              Sign Up
            </a>
          </nav>
        </div>
      </header>
      <section className="hero">
        <div className="container">
          <div className="hero-text">
            <h1>Decentralized, Secure, Immutable modular smart governance and rules layer</h1>
            <div className="buttons">
              <a className="button contained" href="#contact" onClick={handleAnchorClick}>
                Sign up
              </a>
              <a className="button secondary" href="#contact" onClick={handleAnchorClick}>
                Sign in
              </a>
            </div>
          </div>
          <div className="hero-image">
            <img alt="Hero Image" src="images/website/dashboard.jpg" />
          </div>
        </div>
      </section>
      <section className="trust">
        <div className="container">
          <img alt="Trust Logo" height="35px" src="images/website/trust.svg" />
          <img alt="Metamask Logo" height="32px" src="images/website/metamask.svg" />
          <img alt="Metamask Institutional Logo" height="40px" src="images/website/metamask_insitutional.png" />
          <img alt="WalletConnect Logo" height="35px" src="images/website/wallet_connect.png" />
        </div>
      </section>
      <section className="governance" id="features">
        <div className="container">
          <h6>FEATURES</h6>
          <h2>
            On-chain <span>Governance and Rules Engine</span>
          </h2>
          <p>
            DeVaulty network solution is built as a modular layer that could be used independently and is
            <b> completely chain agnostic.</b>
          </p>
          <p>
            Our powerful governance and rules engine is fundamental and the heart of the platform. Our product lines
            vaults and wallet types fit all the needs of our customers in combination with our powerful
            <b> on-chain smart governance and policies</b> that guard the accounts and wallets and
            <b> on-chain smart wallet rules</b> that provide additional rules that enhance security and
            <b> improve UX </b>
            and flows.
          </p>
        </div>
      </section>
      <section className="capabilities">
        <div className="container">
          <h2>
            Truly <span>unleashing</span> Web3 capabilities
          </h2>
          <div className="features">
            <div className="feature">
              <img alt="Smart vaults" src="images/website/smart-vault-icon.svg" />
              <h5>Smart Vaults</h5>
              <p>
                We offer a variety of smart vault types to suit different user needs, from long-term storage to DeFi
                protocol users. With smart on-chain vault governance, users can actively participate in decision-making,
                ensuring transparency and inclusivity. Encoded into smart contracts, our governance rules maintain
                integrity and fairness, facilitating effective collaboration among your team.
              </p>
            </div>
            <div className="feature">
              <img alt="Smart wallets" src="images/website/smart-wallet-icon.svg" />
              <h5>Multiple Smart Wallets</h5>
              <p>
                Including MPC, EOA, and AA, catering to diverse user needs. Users can choose between different risk
                profiles, with options for cold custody and hot trading wallets. We also prioritize disaster recovery
                for wallets, with robust mechanisms to safeguard assets in case of emergencies or unforeseen events.
                DeVaulty also offers mobile personal key recovery for convenient access to funds while maintaining
                security standards.
              </p>
            </div>
            <div className="feature full-width">
              <img alt="Digital assets" src="images/website/digital-asset-icon.svg" />
              <h5>Institutional Digital Asset Management</h5>
              <p>
                Our platform includes a portfolio tracker and supports both permissioned and permissionless DeFi
                activities. With secure asset deployment, users can trade, borrow, lend, and stake assets seamlessly. We
                offer self-execution strategies based on predefined rules for efficient operations. Additionally, our
                platform provides smooth on/off-ramp capabilities, human-readable transactions, and low-latency
                execution for optimal user experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="solutions">
        <div className="container">
          <h6 id="product">PRODUCT OFFERING</h6>
          <h2>
            Multiple solutions for <span>all audiences</span>
          </h2>
          <div className="solutions-grid">
            <div className="solution">
              <img alt="Devaulty web" height={400} src="images/website/devaulty-web-image.png" width={400} />
              <h4>Devaulty web</h4>
              <p>
                Enabling the governance and rules management, creating wallets, tracking activities, balances, and
                transactions.
              </p>
            </div>
            <div className="solution">
              <img alt="Approve app" height={400} src="images/website/approve-app-image.png" width={400} />
              <h4>Approve app</h4>
              <p>
                Approve governance actions, smart rules creation and management, logins, and transaction execution from
                authorized wallets.
              </p>
            </div>
            <div className="solution">
              <img alt="Insta API" height={400} src="images/website/insta-api-image.png" width={400} />
              <h4>Insta API</h4>
              <p>
                Institutional grade asset deployment Web platform. A one-stop shop for institutional participants
                interact in Web3 space institutional asset deployments.
              </p>
            </div>
            <div className="solution">
              <img alt="InstaDeFi web" height={400} src="images/website/insta-defi-web-image.png" width={400} />
              <h4>InstaDeFi web</h4>
              <p>
                Institutional grade API that enables programmatic actions triggering and enables secure and fast low
                latency execution of transactions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="audience">
        <div className="container">
          <h6>AUDIENCE</h6>
          <h2>
            Simple yet <span>built for many</span>
          </h2>
          <div className="audience-list">
            <ul className="vertical-tabs tabs">
              <li id="tab1" onClick={handleTabClick}>
                <a className="tab-link" href="#tab1">
                  <h3>Decentralized Organisations</h3>
                  <img alt="Tab Image" height="64" src="images/website/add-icon.svg" width="64" />
                </a>
                <div className="tab-content">
                  <div className="tab-content-item">
                    <p>
                      Embrace the future of organizational structure with our cutting-edge blockchain solutions. Our
                      platform empowers decentralized autonomous organizations (DAOs) to operate with unprecedented
                      transparency, efficiency, and member engagement.
                    </p>
                    <p>
                      Leverage smart contracts and distributed governance to automate decision-making processes, manage
                      resources, and execute strategies seamlessly. Experience the power of true decentralization, where
                      every stakeholder has a voice and collective intelligence drives innovation.
                    </p>
                  </div>
                </div>
              </li>
              <li id="tab2" onClick={handleTabClick}>
                <a className="tab-link" href="#tab2">
                  <h3>Wallet Providers</h3>
                  <img alt="Tab Image" height="64" src="images/website/add-icon.svg" width="64" />
                </a>
                <div className="tab-content">
                  <div className="tab-content-item">
                    <p>
                      Elevate your wallet services with our state-of-the-art blockchain integration. Our robust API and
                      SDKs enable wallet providers to offer users enhanced security, faster transactions, and seamless
                      interaction with decentralized applications (dApps) across multiple chains.
                    </p>
                    <p>
                      Unlock new possibilities for your users, from effortless cross-chain asset management to
                      participation in decentralized finance (DeFi) protocols. Stay ahead of the curve by providing a
                      gateway to the expanding world of blockchain-powered financial services.
                    </p>
                  </div>
                </div>
              </li>
              <li id="tab3" onClick={handleTabClick}>
                <a className="tab-link" href="#tab3">
                  <h3>Individuals</h3>
                  <img alt="Tab Image" height="64" src="images/website/add-icon.svg" width="64" />
                </a>
                <div className="tab-content">
                  <div className="tab-content-item">
                    <p>
                      Discover the power of blockchain technology in your everyday life. Our user-friendly platform
                      offers individuals easy access to decentralized finance, digital identity management, and secure
                      peer-to-peer transactions without intermediaries.
                    </p>
                    <p>
                      Take control of your digital assets, participate in global markets, and explore new opportunities
                      in the decentralized economy. Whether you&apos;re a crypto enthusiast or a newcomer to blockchain,
                      our intuitive tools and educational resources empower you to navigate the future of finance with
                      confidence.
                    </p>
                  </div>
                </div>
              </li>
              <li id="tab4" onClick={handleTabClick}>
                <a className="tab-link" href="#tab4">
                  <h3>Institutions & Teams</h3>
                  <img alt="Tab Image" height="64" src="images/website/add-icon.svg" width="64" />
                </a>
                <div className="tab-content">
                  <div className="tab-content-item">
                    <p>
                      Transform your organization&apos;s operations with our enterprise-grade blockchain solutions. From
                      supply chain management to secure data sharing, our platform offers scalable, customizable tools
                      that enhance transparency, reduce costs, and streamline processes across departments and partners.
                    </p>
                    <p>
                      Harness the power of distributed ledger technology to build trust, automate compliance, and create
                      new value streams. Our expert team provides comprehensive support to ensure seamless integration,
                      allowing your institution to stay competitive in an increasingly decentralized business landscape.
                    </p>
                  </div>
                </div>
              </li>
              <li id="tab5" onClick={handleTabClick}>
                <a className="tab-link" href="#tab5">
                  <h3>Web 2</h3>
                  <img alt="Tab Image" height="64" src="images/website/add-icon.svg" width="64" />
                </a>
                <div className="tab-content">
                  <div className="tab-content-item">
                    <p>
                      Focused individuals and institutions that can integrate and use our on-chain governance module,
                      with on-chain policies and rules, and use our solution to get out of the box functionalities that
                      are making their Web2 products and services, more flexible, secure, resilient, transparent and
                      immutable.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="networks">
        <div className="container">
          <h2>
            Available on <span>any network</span>
          </h2>
          <ul className="network-icons">
            <li>
              <img alt="Network Icon" src="images/website/bnb-icon.svg" />
              <h5>BNB</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/arbitrum-icon.png" />
              <h5>Arbitrum</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/ethereum-icon.svg" />
              <h5>Ethereum</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/base-icon.png" />
              <h5>Base</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/avalanche-icon.svg" />
              <h5>Avalanche</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/polygon-icon.svg" />
              <h5>Polygon</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/cosmos-icon.png" />
              <h5>Cosmos</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/optimism-icon.png" />
              <h5>Optimism</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/linea-icon.png" />
              <h5>Linea</h5>
            </li>
            <li>
              <img alt="Network Icon" src="images/website/fantom-icon.svg" />
              <h5>Fantom</h5>
            </li>
          </ul>
        </div>
      </section>

      <section className="download-app" id="download-app">
        <div className="container">
          <div className="banner">
            <div className="content">
              <h4>Download deVaulty approve app to get started</h4>
              <p>
                Institutional grade API that enables programmatic actions triggering and enables secure and fast low
                latency execution of transactions.
              </p>
              <div className="buttons">
                <a className="download-button" href="#contact">
                  <img alt="" src="images/website/app-store-icon.svg" /> <span>App Store</span>
                </a>
                <a className="download-button" href="#contact">
                  <img alt="" src="images/website/google-play-icon.svg" />
                  <span>Google Play</span>
                </a>
              </div>
            </div>
            <img alt="App Banner" src="images/website/download-app-image.png" width={422} />
          </div>
        </div>
      </section>

      <section className="team" id="team">
        <div className="container">
          <h2>Team</h2>
          <div className="team-members">
            <div className="team-member">
              <img
                alt="Jurij Zidar"
                className="avatar"
                height={200}
                src="/images/website/team-member-1.png"
                width={200}
              />
              <h5>Jurij Zidar</h5>
              <h6>CEO</h6>
              <a href="https://www.linkedin.com/in/jurij-zidar" rel="noreferrer" target="_blank">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img
                alt="Nik Pletikos"
                className="avatar"
                height={200}
                src="/images/website/team-member-2.png"
                width={200}
              />
              <h5>Nik Pletikos</h5>
              <h6>Lead adviser</h6>
              <a href="https://www.linkedin.com/in/nikpletikos" rel="noreferrer" target="_blank">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img
                alt="Francisco Coquillat"
                className="avatar"
                height={200}
                src="/images/website/team-member-3.png"
                width={200}
              />
              <h5>Francisco Coquillat</h5>
              <h6>Head of Design</h6>
              <a href="https://www.linkedin.com/in/fcoquillat" rel="noreferrer" target="_blank">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img
                alt="Talifa Khoumi"
                className="avatar"
                height={200}
                src="/images/website/team-member-4.png"
                width={200}
              />
              <h5>Talifa Khoumi</h5>
              <h6>Blockchain Engineer</h6>
              <a className="disabled" href="#">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img alt="Avatar" className="avatar" height={200} src="/images/website/team-member-5.png" width={200} />
              <h5>Jarvis Orion</h5>
              <h6>Blockchain Engineer</h6>
              <a className="disabled" href="#">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img alt="Avatar" className="avatar" height={200} src="/images/website/team-member-6.png" width={200} />
              <h5>Jada Starkos</h5>
              <h6>Software Engineer</h6>
              <a className="disabled" href="#">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img alt="Avatar" className="avatar" height={200} src="/images/website/team-member-7.png" width={200} />
              <h5>Cyborgan Stryker</h5>
              <h6>Software Engineer</h6>
              <a className="disabled" href="#">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
            <div className="team-member">
              <img alt="Avatar" className="avatar" height={200} src="/images/website/team-member-8.png" width={200} />
              <h5>Varn Arcturus</h5>
              <h6>Software Engineer</h6>
              <a className="disabled" href="#">
                <img alt="LinkedIn Icon" src="images/website/linkedin-icon.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="platforms" id="platforms">
        <div className="container">
          <img alt="consensys" height={20} src="/images/website/consensys.png" width={92} />
          <img alt="bitstamp" height={28} src="/images/website/bitstamp.png" width={89} />
          <img alt="elixir" height={20} src="/images/website/elixir.png" width={76} />
          <img alt="qredo" height={30} src="/images/website/qredo.png" width={71} />
          <img alt="sweat-economy" height={20} src="/images/website/sweat-economy.png" width={212} />
          <img alt="coinshift" height={24} src="/images/website/coinshift.png" width={83} />
          <img alt="fuel" height={15} src="/images/website/fuel.png" width={68} />
          <img alt="hacken" height={12} src="/images/website/hacken.png" width={114} />
          <img alt="g-2" height={18} src="/images/website/g-2.png" width={57} />
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="container">
          <h2>Get in touch</h2>
          <p>Drop us a message and we will reply shortly!</p>
          <form onSubmit={handleFormSubmit}>
            <input required name="first_name" placeholder="Name *" type="text" />
            <input required name="last_name" placeholder="Last Name *" type="text" />
            <input required name="email" placeholder="Email *" type="email" />
            <input name="phone" placeholder="Phone number" type="phone" />
            <textarea name="message" placeholder="Message" rows={4} value="I want to be a tester!"></textarea>
            <div className="submit-button">
              <button className="button" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="left-column">
            <img alt="Logo" height={60} src="/images/logo_full.svg" width={176} />
            <div>
              <a href="#">Terms</a> | <a href="#">Privacy Policy</a>
            </div>
            <p>© Devaulty. All rights Reserved</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Website;
