import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import WalletDetails from './WalletDetails';

import UsersDetails from './UsersDetails';

import Approvals from './Approvals';

import type { TTabPanelProps } from '../Wallets';

export default function WalletTab(props: TTabPanelProps) {
  const { value, index, wallet, ...other } = props;

  if (value !== index) {
    return null;
  }

  return (
    <Box
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <WalletDetails wallet={wallet} />
          </Grid>
          <Grid item md={4} xs={12}>
            <UsersDetails wallet={wallet} />
          </Grid>
          <Grid item xs={12}>
            <Approvals walletId={wallet.wallet_id} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
