import { Dialog } from '@mui/material';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { EWalletType, useCreateWalletMutation, type TWalletData } from '../../../redux/api';

import CreatingWalletStep from './Steps/CreatingWalletStep';
import WelcomeTo from './Steps/WelcomeTo';
import CreateWalletStep from './Steps/WalletNameStep';
import ManageAccessStep from './Steps/ManageAccessStep/ManageAccessStep';
import WalletCreatedStep from './Steps/WalletCreatedStep';
import WalletThreshold from './Steps/WalletThreshold';

import useWalletCreateFlowContext from '../../../context/WalletCreateFlowContext';

import { Routes } from '../../../components/constants';

import ErrorStep from './Steps/ErrorStep';

import type React from 'react';

export type TFormElements = {
  walletName: HTMLInputElement;
  type: HTMLSelectElement;
  threshold: HTMLSelectElement;
};

export const STEP_NUMBERS = {
  error: -1,
  welcomeTo: 0,
  walletName: 1,
  manageAccess: 2,
  threshold: 3,
  creating: 4,
  created: 5,
} as const;

export const defaultWalletData: TWalletData = {
  walletName: '',
  type: EWalletType.WALLET_TYPE_MPC,
  threshold: '1of6',
};

const CreateWalletFlow: React.FC = () => {
  const { isOpen, setOpen } = useWalletCreateFlowContext();
  const navigate = useNavigate();

  const [stepNumber, _setStepNumber] = useState<number>(STEP_NUMBERS.welcomeTo);
  const [walletData, setWalletData] = useState<TWalletData>(defaultWalletData);

  const [createWalletMutation, { isLoading, isSuccess, data, reset, error, isError }] = useCreateWalletMutation();

  const setStepNumber = useCallback((stepNumber?: number) => {
    _setStepNumber((prevState) => {
      if (stepNumber) {
        return stepNumber;
      }

      return prevState + 1;
    });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);

    reset();

    _setStepNumber(STEP_NUMBERS.walletName);
  }, [reset, setOpen]);

  const createWallet = useCallback(
    async (walletData: TWalletData) => {
      if (isLoading || isSuccess) {
        return;
      }

      _setStepNumber(STEP_NUMBERS.creating);

      const result = await createWalletMutation({
        walletName: walletData.walletName,
        type: walletData.type,
        // threshold: walletData.threshold,
      });

      if ('error' in result) {
        return;
      }

      setWalletData(defaultWalletData);

      setStepNumber(STEP_NUMBERS.created);
    },
    [createWalletMutation, isLoading, isSuccess, setStepNumber],
  );

  const handleGoToWallet = useCallback(() => {
    data?.wallet_id && navigate(`${Routes.WALLETS}/${data.wallet_id}`);
    setOpen(false);
  }, [data?.wallet_id, navigate, setOpen]);

  const step = useMemo(() => {
    switch (stepNumber) {
      case STEP_NUMBERS.error:
        return <ErrorStep error={error} onClose={handleClose} onNext={setStepNumber} />;
      default:

      case STEP_NUMBERS.welcomeTo:
        return <WelcomeTo onClose={handleClose} onNext={setStepNumber} />;
      case STEP_NUMBERS.walletName:
        return (
          <CreateWalletStep
            setWalletData={setWalletData}
            walletData={walletData}
            onClose={handleClose}
            onNext={setStepNumber}
          />
        );
      case STEP_NUMBERS.manageAccess:
        return <ManageAccessStep setWalletData={setWalletData} onClose={handleClose} onNext={setStepNumber} />;
      case STEP_NUMBERS.threshold:
        return (
          <WalletThreshold
            createWallet={createWallet}
            setWalletData={setWalletData}
            walletData={walletData}
            onClose={handleClose}
            onNext={setStepNumber}
          />
        );
      case STEP_NUMBERS.creating:
        return <CreatingWalletStep />;
      case STEP_NUMBERS.created:
        return (
          <WalletCreatedStep
            setWalletData={setWalletData}
            walletAddress={data?.public_key}
            walletData={walletData}
            onClose={handleClose}
            onGoToWallet={handleGoToWallet}
            onNext={setStepNumber}
          />
        );
    }
  }, [createWallet, data?.public_key, error, handleClose, handleGoToWallet, setStepNumber, stepNumber, walletData]);

  useEffect(() => {
    if (isError) {
      setStepNumber(STEP_NUMBERS.error);
    }
  }, [isError, setStepNumber]);

  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: 3, width: '100%' },
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={isOpen}
      onClose={handleClose}
    >
      {step}
    </Dialog>
  );
};

export default CreateWalletFlow;
